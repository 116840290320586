@import 'variables';
@import 'base/font';
@import 'base/root';
@import 'base/reset';

/* Importing Bootstrap grid SCSS file. */
@import '/node_modules/bootstrap/scss/bootstrap-grid.scss';
// angular overlay styles. 1kb size not-gzipped
@import '~@angular/cdk/overlay-prebuilt.css';
@import '/node_modules/ngx-toastr/toastr';

@import 'mixins/modal';
@import 'components/content';
@import 'components/toast';
@import 'components/buttons';
@import 'components/canvas';
@import 'components/backdrop';
@import 'components/loading';
@import 'components/links';
@import 'components/table';
@import 'vendors-override/grid';
@import 'utils';
@import 'keyframes';
