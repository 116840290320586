@use 'mixins/loading';

.loading {
    h2 {
        height: 30px;
        width: 50%;
        margin-bottom: 4px;
    }
    h3 {
        height: 24px;
    }
    h4,
    h5 {
        height: 20px;
    }
    h6 {
        height: 10px;
    }
    p {
        height: 14px;
        width: 30%;
    }
    hr {
        border: none;
        height: 2px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    .avatar,
    button,
    .animate {
        @include loading.content-loading();
    }
}

button.loading {
    @include loading.content-loading();

    svg-icon {
        display: none;
    }
}
