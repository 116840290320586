@import 'variables';

/* Font Styles Start */
@font-face {
    font-family: 'conti-book';
    src: url('/assets/fonts/ContinentalStagSansLCG-Book.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'conti-book-italic';
    src: url('/assets/fonts/ContinentalStagSansLCG-BookIt.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-BookIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'conti-light';
    src: url('/assets/fonts/ContinentalStagSansLCG-Light.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'conti-light-italic';
    src: url('/assets/fonts/ContinentalStagSansLCG-LightIt.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'conti-med';
    src: url('/assets/fonts/ContinentalStagSansLCG-Medium.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'conti-med-italic';
    src: url('/assets/fonts/ContinentalStagSansLCG-MediumIt.woff2') format('woff2'),
        url('/assets/fonts/ContinentalStagSansLCG-MediumIt.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

strong {
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-underline {
    text-decoration: underline;
}

@each $font, $value in $font-families {
    .font-#{$font} {
        font-family: $value;
    }
}

@each $size, $value in $font-sizes {
    .text-#{$size} {
        font-size: $value;
    }
}

@each $color, $value in $theme-colors {
    .text-#{$color}.text-#{$color} {
        color: $value;
    }
}

@each $color, $value in $colors {
    .text-#{$color} {
        color: $value;
    }
}
