:root {
    // Note: Custom variable values only support SassScript inside `#{}`.

    // Colors

    // Generate palettes for full colors, grays, and theme colors.

    @each $color, $value in $colors {
        --color-#{$color}: #{$value};
    }

    @each $metric-color, $value in $metric-colors {
        --color-metric-#{$metric-color}: #{$value};
    }

    @each $loader-colors, $value in $loader-colors {
        --color-loader-#{$loader-colors}: #{$value};
    }

    // @each $color, $value in $grays {
    //   --#{$variable-prefix}gray-#{$color}: #{$value};
    // }

    @each $color, $value in $theme-colors {
        --color-#{$color}: #{$value};
    }

    @each $font, $value in $font-families {
        --font-family-#{$font}: #{$value};
    }

    @each $size, $value in $font-sizes {
        --font-size-#{$size}: #{$value};
    }

    @each $shadow-color, $value in $shadow {
        --shadow-#{$shadow-color}: #{$value};
    }

    @each $dimension, $value in $dimensions {
        --dimensions-#{$dimension}: #{$value};
    }

    // @each $color, $value in $theme-colors-rgb {
    //   --#{$variable-prefix}#{$color}-rgb: #{$value};
    // }

    // --#{$variable-prefix}white-rgb: #{to-rgb($white)};
    // --#{$variable-prefix}black-rgb: #{to-rgb($black)};
    // --#{$variable-prefix}body-color-rgb: #{to-rgb($body-color)};
    // --#{$variable-prefix}body-bg-rgb: #{to-rgb($body-bg)};

    // Fonts

    // Note: Use `inspect` for lists so that quoted items keep the quotes.
    // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
    // --font-sans-serif: #{inspect($font-family-sans-serif)};
    // --font-monospace: #{inspect($font-family-monospace)};
    // --gradient: #{$gradient};

    // Root and body
    // stylelint-disable custom-property-empty-line-before
    // scss-docs-start root-body-variables
    @if $font-size-base != null {
        --base-font-size: #{$font-size-base};
    }
    --body-font-family: #{$font-family-base};
    --body-font-size: #{$font-size-body};
    --body-color: #{$base-color};
    --border-radius: #{$border-radius};
}
