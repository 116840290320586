$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

// Define mixin
@mixin media-breakpoint-up($breakpoint, $grid-breakpoints) {
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
        @content;
    }
}

@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        .toast-container {
            max-width: $container-max-width;
        }
    }
}
