@use 'mixins/shadow';
@use 'mixins/content';
@use 'variables';
@use 'vendors-override/toast-container';

@mixin toast-info-background($color) {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'><path fill='#{rgba($color, 1)}' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/> </svg>");
}

@mixin center-background-image($image, $size) {
    background-image: url($image);
    background-repeat: no-repeat;
    background-size: $size;
    background-position: center;
}

.toast-container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.toast-container .toast {
    @include shadow.box-shadow();
    @include content.content-wrapper();
    padding: 20px 50px;
    margin: 0 0 6px;
    background-color: var(--color-white);
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    overflow: hidden;
    width: 100%;

    .toast-title {
        display: none;
    }

    &::before {
        content: '';
        width: 40px;
        display: block;
        position: absolute;
        z-index: 999;
        height: 100%;
        top: 0;
        left: 0;
    }

    &.toast-error::before {
        background-color: variables.$danger;
        @include center-background-image('/assets/images/icons/toast/error.svg', 20px);
    }

    &.toast-success::before {
        background-color: variables.$success;
        @include center-background-image('/assets/images/icons/toast/success.svg', 20px);
    }

    &.toast-info::before {
        background-color: variables.$info;
        color: variables.$white;
        @include toast-info-background(variables.$primary);
        @include center-background-image('/assets/images/icons/toast/info.svg', 20px);
    }

    &.toast-warning::before {
        background-color: variables.$warning;
        @include center-background-image('/assets/images/icons/toast/warning.svg', 20px);
    }

    &.toast-new-assessment::before {
        background-color: variables.$success;
        @include center-background-image('/assets/images/icons/toast/assessment.svg', 20px);
    }

    &.toast-new-connection::before {
        background-color: variables.$success;
        @include center-background-image('/assets/images/icons/toast/connection_request.svg', 20px);
    }

    .toast-close-button {
        height: 20px;
        width: 20px;
        top: 50%;
        position: absolute;
        right: 16px;
        transform: translateY(-50%);
        @include center-background-image('/assets/images/icons/close.svg', 12px);

        span {
            opacity: 0;
        }
    }

    &.toast-new-assessment,
    &.toast-new-connection {
        .toast-title {
            display: block;
            float: left;
            font-family: var(--font-family-med);
            margin-right: var(--dimensions-xxs);
        }

        .toast-message {
            float: left;
        }

        .toast-close-button {
            @include center-background-image('/assets/images/explore_2.svg', 20px);
            pointer-events: none; // hack to make explore button work
        }
    }
}

.logged-in .toast {
    margin-left: 26px !important;
    margin-right: 26px !important;
}
