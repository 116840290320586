@use 'mixins/shadow';

.sendButton {
    border-radius: 24px;
    font-size: $font-size-body;
    height: 39px;
    min-width: 186px;
    display: inline-block;
    cursor: pointer;
    letter-spacing: -0.041em;
    color: var(--color-gray);
    overflow: hidden;
    background-color: white;
    font-weight: bold;
    border: 1px solid var(--color-orange);

    &:active,
    &:focus,
    &:hover {
        background: var(--color-orange);
        color: black;
        transition: 0.5s;
    }

    &:disabled {
        background-color: transparent;
        transition: 0.5s;

        &:hover {
            color: var(--color-gray);
        }
    }
}

.yellowButton {
    font-size: 1rem;
    padding: 10px 20px;
    background: var(--color-orange);
    border-radius: 24px;
    line-height: 1;
    text-align: center;
    color: var(--color-gray-dark);
    border: 1px solid var(--color-orange);
    cursor: pointer;
    max-width: 100%;
    justify-content: center;

    &:disabled,
    &.disabled {
        pointer-events: none;
        color: var(--color-disabled);
        background: var(--color-gray-tint);
        border: 1px solid var(--color-gray-tint);
    }

    &:active,
    &:hover,
    &:focus {
        @include shadow.box-shadow();
        border: 1px solid var(--color-gray);
    }
}

.whiteButton {
    font-size: 1rem;
    padding: 10px 20px;
    background: var(--color-white);
    border-radius: 24px;
    line-height: 1;
    text-align: center;
    border: 1px solid var(--color-orange);
    color: var(--color-gray);
    cursor: pointer;
    justify-content: center;

    &:disabled,
    &.disabled {
        color: var(--color-gray);
        background: var(--color-white);
        border: 1px solid var(--color-gray);
    }
}

.noButton {
    all: unset;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
