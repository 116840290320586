// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
    @if $font-size-base != null {
        font-size: var(--root-font-size);
    }

    @if $enable-smooth-scroll {
        @media (prefers-reduced-motion: no-preference) {
            scroll-behavior: smooth;
        }
    }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
    margin: 0; // 1
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    line-height: 1.15;
    color: var(--body-color);
    -webkit-text-size-adjust: 100%; // 3
    -webkit-tap-highlight-color: rgba($black, 0); // 4
}

%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    color: $headings-color;
}

h1 {
    @extend %heading;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
    font-size: $h1-font-size;
    letter-spacing: $h1-letter-spacing;
    margin: 0;
}

h2 {
    @extend %heading;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    letter-spacing: $h2-letter-spacing;
}

h3 {
    @extend %heading;
    font-size: $h3-font-size;
}

h4 {
    @extend %heading;
    font-size: $h4-font-size;
}

h5 {
    @extend %heading;
    font-size: $h5-font-size;
}

h6 {
    @extend %heading;
    font-size: $h6-font-size;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
    display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

button:focus:not(:focus-visible) {
    outline: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
    margin: 0; // 1
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    &:focus-visible {
        outline: none;
    }
}

// Remove the inheritance of text transform in Firefox
button,
select {
    text-transform: none;
}
// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role='button'] {
    cursor: pointer;
}

select {
    // Remove the inheritance of word-wrap in Safari.
    // See https://github.com/twbs/bootstrap/issues/24990
    word-wrap: normal;

    // Undo the opacity change from Chrome
    &:disabled {
        opacity: 1;
    }
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
    resize: vertical; // 1
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
    min-width: 0; // 1
    padding: 0; // 2
    margin: 0; // 2
    border: 0; // 2
}

// 1. By using `float: left`, the legend will behave like a block element.
//    This way the border of a fieldset wraps around the legend if present.
// 2. Fix wrapping bug.
//    See https://github.com/twbs/bootstrap/issues/29712

legend {
    float: left; // 1
    width: 100%;
    padding: 0;
    line-height: inherit;

    + * {
        clear: left; // 2
    }
}

// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type='search'] {
    outline-offset: -2px; // 1
    -webkit-appearance: textfield; // 2
}

// 1. A few input types should stay LTR
// See https://rtlstyling.com/posts/rtl-styling#form-inputs
// 2. RTL only output
// See https://rtlcss.com/learn/usage-guide/control-directives/#raw

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
    -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
    padding: 0;
}

// 1. Inherit font family and line height for file input buttons
// 2. Correct the inability to style clickable types in iOS and Safari.

::file-selector-button {
    font: inherit; // 1
    -webkit-appearance: button; // 2
}

// Correct element displays

output {
    display: inline-block;
}

// Remove border from iframe

iframe {
    border: 0;
}

// Summary
//
// 1. Add the correct display in all browsers

summary {
    display: list-item; // 1
    cursor: pointer;
}

// Progress
//
// Add the correct vertical alignment in Chrome, Firefox, and Opera.

progress {
    vertical-align: baseline;
}

// Figures
//
// Apply a consistent margin strategy (matches our type styles).

figure {
    margin: 0 0 1rem;
}

// Images and content

img,
svg {
    vertical-align: middle;
}

// Tables
//
// Prevent double borders

table {
    caption-side: bottom;
    border-collapse: collapse;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
    display: none !important;
}

ul,
li,
ul li {
    list-style-type: none !important;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}
